'use client';
import { atom } from 'jotai';
import { atomFamily, unwrap } from 'jotai/utils';

import { localeAtom } from '../Locale/Locale.atom';
import { fetchJson } from '@core/Utils/Fetch/FetchJson';
import { simpleFetch } from '@core/Utils/Fetch/SimpleFetch';
import { SearchResults } from '@core/Entities/Search/SearchResults.entity';
import { mappedResponse } from '@core/Services/Search/Utils/mapResponse';

export const asyncVehicleByIdAtom = atomFamily((vehicleId: string) =>
  unwrap(
    atom(async get => {
      const locale = get(localeAtom);

      const urls = {
        uk: `${process.env.NEXT_PUBLIC_UK_I15_SEARCH_API_URL}?heycar-id=${vehicleId}`,
        fr: `${process.env.NEXT_PUBLIC_FR_I15_SEARCH_API_URL}?heycar-id=${vehicleId}`,
        // TODO: Change with de search api url later
        de: `${process.env.NEXT_PUBLIC_FR_I15_SEARCH_API_URL}?heycar-id=${vehicleId}`,
      };
      const searchRequest = fetchJson<SearchResults>(urls[locale], {
        next: { revalidate: 60 },
      });

      const [[searchResults], { hasError }] = await simpleFetch([searchRequest]);

      return hasError ? null : mappedResponse(searchResults).content[0];
    }),
  ),
);
